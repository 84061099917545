import "./index.scss";
import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "../../components/Home";
import AppNavigation from "../../components/App-Navigation";
import AppFooter from "../../components/App-Footer";
import About from "../../components/About";
import Reviews from "../../components/Reviews";

function RootLayout({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  });

  return (
    <div className="container" id="main">
      <AppNavigation />
      <Switch>
        <Route exact path={"/"} render={() => <Home />} />
        <Route exact path={"/about"} render={() => <About />} />
        <Route exact path={"/reviews"} render={() => <Reviews />} />
      </Switch>
      <AppFooter />
    </div>
  );
}
export default withRouter(RootLayout);
