import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

const AppNavigation = () => {
  return (
    <div className="col-12 mad-nav mad-theme">
      <div className="mad-nav-logo">
        <Link to="/">
          <img src="/img/logo.jpg" width="60" alt="logo" />
          <h4>Mauzy's Amazing Doodles</h4>
        </Link>
      </div>

      <div className="mad-nav-links">
        <Link to="/">home</Link>
        <Link to="/about">about</Link>
        <Link to="/reviews">reviews</Link>
      </div>
    </div>
  );
};
export default AppNavigation;
