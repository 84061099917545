import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RootLayout from '../layouts/RootLayout';
import NotFound from '../components/Not-Found';

export default class Routes extends Component {
    render() {
        return (
            <Router>
                <div>
                    {/* Only need 1 switch statement */}
                    <Switch>
                        {/* 
                        These routes fire only if the path exactly matches the route 
                        and then it defers the route to the RootLayout Component and matches
                        in that component.   
                        */}
                        <Route exact path={'/'} component={RootLayout} />
                        <Route exact path={'/about'} component={RootLayout} />
                        <Route exact path={'/reviews'} component={RootLayout} />
                        <Route path={'/'} render={() => <Route component={NotFound} />} />
                        {/* 
                        This route fires if it doesn't exactly patch the '/' or other routes.
                        All routes that don't match the above routes, must match below or they'll 404
                    */}
                    </Switch>
                </div>
            </Router>
        );
    }
}
