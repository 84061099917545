import './index.scss';
import React, { Component } from 'react';

export default class NotFound extends Component {

    render() {
        return (
            <div className="mad-not-found-page mad-theme">
                <h4>404</h4>
                <p>Not Found</p>
            </div>
        )
    }
}