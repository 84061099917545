import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

const AppFooter = () => {
  return (
    <div className="mad-footer-container">
      <div className="col-12 mad-resources-container mad-dark">
        <div className="row">
          <div className="col-12 col-md-4 mad-resource">
            <h4>Mauzy's Amazing Doodles</h4>
            <img
              src="/img/logo.jpg"
              width="70%"
              alt="Mauzy's Amazing Doodles"
            />
          </div>
          <div className="col-12 col-md-4 mad-resource">
            <h4>Contact Us</h4>

            <div className="mad-social-links">
              <a
                href="https://www.facebook.com/Mauzys-Amazing-Doodles-220386065459902/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/FB.png" width="40px" alt="Facebook logo" />
              </a>
              <a
                href="https://www.instagram.com/mauzys_amazingdoodles/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/IG_Glyph_Fill.png"
                  width="40px"
                  alt="Instagram logo"
                />
              </a>
            </div>

            <p>
              <b>Location</b>
              <br />
              Ripon, CA
              <br />
              <br />
            </p>

            <p>
              <b>Phone</b>
              <br />
              <a href="tel:760-521-4599">(760) 521-4599</a>
              <br />
              <br />

              <b>Email</b>
              <br />
              <a href="mailto:ruth@mauzysamazingdoodles.com">
                ruth@mauzysamazingdoodles.com
              </a>
            </p>

            <div className="image-link">
              <img src="/img/badass.jpg" alt="Badass breeder certificate" />
            </div>
          </div>

          <div className="col-12 col-md-4 mad-resource">
            <h4>Resources</h4>
            <div>
              <a
                href="https://forms.gle/3wwy7hS28kD3UYdb7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Goldendoodle Application
              </a>
            </div>

            <div className="image-link-wrapper">
              <div className="image-link">
                <a
                  href="https://www.baxterandbella.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/b-and-b.jpg" alt="Baxter and Bella" />

                  <div className="text-wrapper">
                    <h4>Baxter & Bella</h4>
                    <p>use code MAD25 for 25% off</p>
                  </div>
                </a>
              </div>

              <div className="image-link">
                <a
                  href="https://shoppuppyculture.com/pages/puppy-culture-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/Puppy+Culture.jpeg" alt="Baxter and Bella" />
                  <div className="text-wrapper">
                    <h4>Puppy Culture</h4>
                  </div>
                </a>
              </div>

              <div className="image-link">
                <a
                  href="https://tlcpetfood.com/nutrition/mauzysamazingdoodles/?fbclid=IwAR000fS9zieoEQ_66GgU7Oy3XsBywCZmZ-FHlpQQ_e9diXdEnlaiDHQIt9Y&mibextid=Zxz2cZ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://tlcpetfood.com/wp-content/uploads/2021/12/TLC_logo_2C-no-URL.png"
                    alt="TLC Pet Food"
                  />
                  <div className="text-wrapper">
                    <h4>TLC Pet Food</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mad-footer mad-theme">
        <div className="mad-footer-logo">
          <p>© Copyright 2024 | All rights reserved</p>
        </div>

        <div className="mad-footer-links">
          <Link to="/">home</Link>
          <Link to="/about">about</Link>
          <Link to="/reviews">reviews</Link>
        </div>
      </div>
    </div>
  );
};
export default AppFooter;
